import React, { useEffect, useRef } from "react";

const Calendar = () => {
  const iframeRef = useRef(null);
  useEffect(() => {
    const interval = setInterval(() => {
      if (iframeRef.current) {
        const iframeSrc = new URL(iframeRef.current.src);

        // Get the current query parameters as a Map
        const params = new URLSearchParams(iframeSrc.search);

        // Append a timestamp to the existing parameters (to bypass cache)
        params.set("t", new Date().getTime()); // Add a cache-busting timestamp

        // Rebuild the URL with the new parameters
        iframeSrc.search = params.toString();

        // Set the new src with the updated query parameters
        iframeRef.current.src = iframeSrc.toString();
      }
    }, 2 * 60 * 1000); // Refresh every 2 minutes (adjust as needed)

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <iframe
      ref={iframeRef}
      className="Rent-calendar"
      src="https://calendar.google.com/calendar/embed?height=350&wkst=2&ctz=Europe%2FOslo&&bgcolor=%23ffffff&showPrint=0&showTitle=0&showTabs=0&showCalendars=0&showTz=0&src=ZnJlZHJpa3Nib3JnMTkyMEBnbWFpbC5jb20&color=%230B8043"
    ></iframe>
  );
};

export default Calendar;
